exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-clarification-index-tsx": () => import("./../../../src/pages/clarification/index.tsx" /* webpackChunkName: "component---src-pages-clarification-index-tsx" */),
  "component---src-pages-clarification-list-tsx": () => import("./../../../src/pages/clarification/list.tsx" /* webpackChunkName: "component---src-pages-clarification-list-tsx" */),
  "component---src-pages-clarification-offer-tsx": () => import("./../../../src/pages/clarification/offer.tsx" /* webpackChunkName: "component---src-pages-clarification-offer-tsx" */),
  "component---src-pages-clarification-onboarding-tsx": () => import("./../../../src/pages/clarification/onboarding.tsx" /* webpackChunkName: "component---src-pages-clarification-onboarding-tsx" */),
  "component---src-pages-clarification-success-client-tsx": () => import("./../../../src/pages/clarification/success-client.tsx" /* webpackChunkName: "component---src-pages-clarification-success-client-tsx" */),
  "component---src-pages-clarification-success-tsx": () => import("./../../../src/pages/clarification/success.tsx" /* webpackChunkName: "component---src-pages-clarification-success-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-deprecated-tsx": () => import("./../../../src/pages/landing/DEPRECATED.tsx" /* webpackChunkName: "component---src-pages-landing-deprecated-tsx" */),
  "component---src-pages-landing-index-tsx": () => import("./../../../src/pages/landing/index.tsx" /* webpackChunkName: "component---src-pages-landing-index-tsx" */),
  "component---src-pages-privacity-tsx": () => import("./../../../src/pages/privacity.tsx" /* webpackChunkName: "component---src-pages-privacity-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-products-offer-tsx": () => import("./../../../src/pages/products/offer.tsx" /* webpackChunkName: "component---src-pages-products-offer-tsx" */),
  "component---src-pages-products-onboarding-tsx": () => import("./../../../src/pages/products/onboarding.tsx" /* webpackChunkName: "component---src-pages-products-onboarding-tsx" */),
  "component---src-pages-products-success-client-tsx": () => import("./../../../src/pages/products/success-client.tsx" /* webpackChunkName: "component---src-pages-products-success-client-tsx" */),
  "component---src-pages-products-success-tsx": () => import("./../../../src/pages/products/success.tsx" /* webpackChunkName: "component---src-pages-products-success-tsx" */),
  "component---src-pages-products-turn-tsx": () => import("./../../../src/pages/products/turn.tsx" /* webpackChunkName: "component---src-pages-products-turn-tsx" */),
  "component---src-pages-propuestas-1-tsx": () => import("./../../../src/pages/propuestas/1.tsx" /* webpackChunkName: "component---src-pages-propuestas-1-tsx" */),
  "component---src-pages-propuestas-2-tsx": () => import("./../../../src/pages/propuestas/2.tsx" /* webpackChunkName: "component---src-pages-propuestas-2-tsx" */),
  "component---src-pages-propuestas-component-tsx": () => import("./../../../src/pages/propuestas/Component.tsx" /* webpackChunkName: "component---src-pages-propuestas-component-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-list-tsx": () => import("./../../../src/pages/services/list.tsx" /* webpackChunkName: "component---src-pages-services-list-tsx" */),
  "component---src-pages-services-offer-tsx": () => import("./../../../src/pages/services/offer.tsx" /* webpackChunkName: "component---src-pages-services-offer-tsx" */),
  "component---src-pages-services-onboarding-tsx": () => import("./../../../src/pages/services/onboarding.tsx" /* webpackChunkName: "component---src-pages-services-onboarding-tsx" */),
  "component---src-pages-services-success-client-tsx": () => import("./../../../src/pages/services/success-client.tsx" /* webpackChunkName: "component---src-pages-services-success-client-tsx" */),
  "component---src-pages-services-success-tsx": () => import("./../../../src/pages/services/success.tsx" /* webpackChunkName: "component---src-pages-services-success-tsx" */),
  "component---src-pages-services-turn-tsx": () => import("./../../../src/pages/services/turn.tsx" /* webpackChunkName: "component---src-pages-services-turn-tsx" */),
  "component---src-pages-start-tsx": () => import("./../../../src/pages/start.tsx" /* webpackChunkName: "component---src-pages-start-tsx" */),
  "component---src-pages-sucursales-mapas-index-tsx": () => import("./../../../src/pages/sucursales/mapas/index.tsx" /* webpackChunkName: "component---src-pages-sucursales-mapas-index-tsx" */),
  "component---src-pages-turnos-index-tsx": () => import("./../../../src/pages/turnos/index.tsx" /* webpackChunkName: "component---src-pages-turnos-index-tsx" */)
}

